import React from 'react';

import { CarouselSlider } from '@sprinklr/shared-lib/components/carouselSlider';
import { Media } from '@sprinklr/shared-lib';
import { Box } from 'theme-ui';

import { CAROUSEL_STYLES } from './styles';

export const Carousal = ({ carousel }) => {
  return (
    <Box sx={CAROUSEL_STYLES}>
      <CarouselSlider {...carousel} arrows={carousel.showArrow}>
        {carousel.media.map(_media => (
          <Media mediaType={_media.mediaType} source={_media.asset} />
        ))}
      </CarouselSlider>
    </Box>
  );
};
