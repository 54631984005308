const defaultArrowStyles = {
  height: '32px',
  width: '32px',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  ':hover': {
    border: '1px solid black',
  },
};

export const CAROUSEL_STYLES = {
  paddingX: ['30px', '0px'],
  '.slick-prev': {
    '&::before': {
      content: 'none',
    },
  },
  '.slick-next': {
    '&::before': {
      content: 'none',
    },
  },
  '.slick-arrow.slick-prev': {
    ...defaultArrowStyles,
    left: '-40px',
  },
  '.slick-arrow.slick-next': {
    ...defaultArrowStyles,
    right: '-40px',
  },
  '.gatsby-image-wrapper': {
    borderRadius: '0px',
  },
};
